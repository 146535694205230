import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Dialog, DialogContent, Fab, Grid, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useAppSelector } from '../../redux/hooks';
import { FormattedMessage } from 'react-intl';
import LoginForm from './LoginForm';
import { selectPlayer } from '../../reducers/playerSlice';
import { FivezerLogoIcon } from '../../img/icons/Icons';

interface IModal {
  handleClose: any;
  open: boolean;
}

const LoginModal: React.FC<IModal> = (props: IModal) => {
  const loggedPlayer = useAppSelector(selectPlayer);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '80%' }, p: '20px 10px' },
      }}
      sx={{
        zIndex: '1000000000',
      }}
    >
      <Fab
        size="small"
        onClick={props.handleClose}
        className="button-cancel"
        sx={{
          boxShadow: 'none',
          bgcolor: 'transparent',
          ml: '2%',
        }}
      >
        <Close />
      </Fab>
      <DialogContent>
        <Grid item xs={12} md={12} lg={12}>
          <Grid className={'logo'} sx={{ padding: '0 10%', textAlign: 'center' }}>
            {loggedPlayer?.app_configuration?.previous_user?.username ? (
              <Avatar
                className="avatar"
                imgProps={{
                  referrerPolicy: 'no-referrer',
                }}
                alt={loggedPlayer?.app_configuration?.previous_user?.username}
                src={loggedPlayer?.app_configuration?.previous_user?.avatar?.path}
                sx={{
                  m: 'auto',
                  width: { xs: 100, sm: 130, md: 160, lg: 250 },
                  height: { xs: 100, sm: 130, md: 160, lg: 250 },
                  borderWidth: '6px',
                  bgcolor: 'rgba(247, 251, 250, .3)',
                }}
              />
            ) : (
              <FivezerLogoIcon
                sx={{
                  height: 'auto',
                  width: { xs: '22%', sm: '20%', md: '15%', lg: '20%' },
                  margin: 'auto',
                }}
              />
            )}
          </Grid>
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: '2rem', sm: '3rem', md: '4rem' },
              fontWeight: '100 !important',
              display: 'inline-block',
              padding: '0 10% 50px',
              textAlign: 'center',
              width: '100%',
            }}
            className={'title'}
          >
            <FormattedMessage id="LoginPage_Hello" />
            &nbsp;
            <Grid sx={{ fontWeight: '800', display: 'inline-block' }}>{loggedPlayer?.app_configuration?.previous_user?.username ? <>{loggedPlayer?.app_configuration?.previous_user?.username}</> : <FormattedMessage id="LoginPage_Friend" />}</Grid>
            ,<br />
            <FormattedMessage id="LoginPage_Welcome_Back" />
          </Typography>
          <Grid
            className={'padding-left padding-right'}
            sx={{
              m: 'auto',
              width: { xs: '90%', sm: '80%', md: '70%' },
            }}
          >
            <LoginForm destination={window.location.pathname} handleClose={props.handleClose} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

LoginModal.propTypes = {
  handleClose: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
};

LoginModal.defaultProps = {
  open: false,
};

export default React.memo(LoginModal);
