export const REACT_APP_API_LAYER_KEY = process.env.REACT_APP_API_LAYER_KEY || '';
export const REACT_APP_CUSTOM_MAP_URL = process.env.REACT_APP_CUSTOM_MAP_URL || '';
export const REACT_APP_MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_API_KEY || '';
export const REACT_APP_GRAPHQL_URI = process.env.REACT_APP_GRAPHQL_URI || '';
export const REACT_APP_ENCRYPT_KEY = process.env.REACT_APP_ENCRYPT_KEY || '';
export const REACT_APP_CLIENT_NAME = process.env.REACT_APP_CLIENT_NAME || 'FIVEZER';
export const REACT_APP_IP_INFO_TOKEN = process.env.REACT_APP_IP_INFO_TOKEN || '';
export const REACT_APP_GRAPHQL_WEBSOCKET_URI = process.env.REACT_APP_GRAPHQL_WEBSOCKET_URI || '';
export const REACT_APP_GRAPHQL_LIVESTREAM_WEBSOCKET_URI = process.env.REACT_APP_GRAPHQL_LIVESTREAM_WEBSOCKET_URI || '';
export const REACT_APP_LILENEK_URL = process.env.REACT_APP_LILENEK_URL || '';
