import React from 'react';
import { Grid, IconButton, CardMedia, Stack, Avatar, Typography, Slider, MenuItem, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import { ArrowDownward, ArrowUpward, ChatBubble, Favorite, Mic, MicOff, Visibility } from '@mui/icons-material';
import SwipeableViews from 'react-swipeable-views';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useAppSelector } from '../../redux/hooks';
import { selectSession } from '../../reducers/sessionSlice';
import _ from 'lodash';
import NumberFormatter from './NumberFormatter';
import { Controller, useForm } from 'react-hook-form';
import LoginModal from '../profile/LoginModal';
import { selectPlayer } from '../../reducers/playerSlice';
import LivestreamVideoPlayer from '../features/LivestreamVideoPlayer';
import { LiveIcon, ShareIcon } from '../../img/icons/Icons';
import ViewCommentsModal from '../contacts/ViewCommentsModal';

interface IInfiniteVideoScroll {
  elements: any[];
}

interface IInfiniteVideoPlayer {
  key: number;
  live: any;
  next_element?: any;
  previous_element?: any;
}

interface IVideoPlayer {
  url: string;
  muted: boolean;
}

const InfiniteVideoScroll: React.FC<IInfiniteVideoScroll> = (props: IInfiniteVideoScroll) => {
  const [currentStep, setCurrentStep] = React.useState(0);

  const handleStepChange = (step: number) => {
    setCurrentStep(step);
  };

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <Grid item xl={12} xs={12} sm={12} sx={{ height: '100%', display: 'inline-block' }}>
      <SwipeableViews
        index={currentStep}
        onChangeIndex={handleStepChange}
        axis="y"
        enableMouseEvents
        resistance
        containerStyle={{ height: '100vh' }} // Ensures each slide occupies full screen height
        slideStyle={{ height: '100vh' }} // Ensures each slide occupies full screen height
      >
        {props.elements?.map((live: any, index: number) => (
          <InfiniteVideoPlayer key={index} live={live} next_element={handleNextStep} previous_element={currentStep === 0 ? undefined : handlePreviousStep} />
        ))}
      </SwipeableViews>
    </Grid>
  );
};

const InfiniteVideoPlayer: React.FC<IInfiniteVideoPlayer> = (props: IInfiniteVideoPlayer) => {
  const FIND_LIVESTREAM_BY_ID_QUERY = gql`
    query findLivestreamByID($livestream: inputFindLivestreamByID!) {
      findLivestreamByID(livestream: $livestream) {
        id
        room_id
        title
        status
        anonymous_participants
        participants {
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        liked_by {
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        shared_by {
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        comments {
          id
          title
          mood
          content
          likes
          author {
            ... on Company {
              __typename
              id
              name
              label
              logo {
                path
              }
            }
            ... on User {
              __typename
              id
              username
              avatar {
                path
              }
            }
            ... on Player {
              __typename
              id
              username
              avatar {
                path
              }
            }
          }
          created_at
          updated_at
        }
        created_at
      }
    }
  `;

  const LIKE_LIVESTREAM_MUTATION = gql`
    mutation likeLivestream($livestream: inputReactLivestream!) {
      likeLivestream(livestream: $livestream)
    }
  `;

  const DISLIKE_LIVESTREAM_MUTATION = gql`
    mutation dislikeLivestream($livestream: inputReactLivestream!) {
      dislikeLivestream(livestream: $livestream)
    }
  `;

  const SHARE_LIVESTREAM_MUTATION = gql`
    mutation shareLivestream($livestream: inputReactLivestream!) {
      shareLivestream(livestream: $livestream)
    }
  `;

  const UNSHARE_LIVESTREAM_MUTATION = gql`
    mutation unshareLivestream($livestream: inputReactLivestream!) {
      unshareLivestream(livestream: $livestream)
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const [livestream, setLivestream] = React.useState(UNDEFINED_AS_ANY);
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [isMuted, setIsMuted] = React.useState(true);
  const [openViewComments, setOpenViewComments] = React.useState(false);
  const [openLogin, setOpenLogin] = React.useState(false);

  const { control, watch, setValue } = useForm({
    defaultValues: {
      flexer_id: '',
    },
  });
  const inputFields = watch();
  const ALL_FLEXERS = _.concat({
    id: loggedPlayer?.id,
    name: loggedPlayer?.username,
    avatar: loggedPlayer?.avatar?.path,
  });

  const [likeLivestream, likedLivestream] = useMutation(LIKE_LIVESTREAM_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [dislikeLivestream, dislikedLivestream] = useMutation(DISLIKE_LIVESTREAM_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [shareLivestream, sharedLivestream] = useMutation(SHARE_LIVESTREAM_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [unshareLivestream, unsharedLivestream] = useMutation(UNSHARE_LIVESTREAM_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedLivestream = useQuery(FIND_LIVESTREAM_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || !loggedPlayer?.id || !props.live?.room_id,
    variables: {
      livestream: {
        id: props.live?.room_id,
        comments_order: 'DESC',
      },
    },
  });

  const handleRefetch = async () => {
    try {
      const result = await loadedLivestream.refetch({
        livestream: {
          id: props.live?.room_id,
          comments_order: 'DESC',
        },
      });

      if (result.data?.findLivestreamByID) {
        setLivestream(result.data?.findLivestreamByID);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleLikeLivestream = async (livestream_id: string) => {
    try {
      await likeLivestream({
        variables: {
          livestream: {
            id: livestream_id,
            participant_id: inputFields.flexer_id,
          },
        },
      });

      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  const handleDislikeLivestream = async (livestream_id: string) => {
    try {
      await dislikeLivestream({
        variables: {
          livestream: {
            id: livestream_id,
            participant_id: inputFields.flexer_id,
          },
        },
      });

      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  const handleShareLivestream = async (livestream_id: string) => {
    try {
      await shareLivestream({
        variables: {
          livestream: {
            id: livestream_id,
            participant_id: inputFields.flexer_id,
          },
        },
      });

      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  const handleUnshareLivestream = async (livestream_id: string) => {
    try {
      await unshareLivestream({
        variables: {
          livestream: {
            id: livestream_id,
            participant_id: inputFields.flexer_id,
          },
        },
      });

      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenLogin = () => {
    setOpenLogin(true);
  };

  React.useEffect(() => {
    // Automatically start the video when the component mounts
    if (videoRef.current) {
      // videoRef.current.play();
      // setIsPlaying(true);
    }
  }, []);

  React.useEffect(() => {
    if (inputFields.flexer_id === '') setValue('flexer_id', ALL_FLEXERS[0]?.id);
  }, [inputFields, setValue, ALL_FLEXERS]);

  React.useEffect(() => {
    if ((!livestream && loadedLivestream.data?.findLivestreamByID) || (livestream && livestream?.room_id !== props.live?.room_id) || (loadedLivestream.data?.findLivestreamByID && !_.isMatch(livestream, loadedLivestream.data?.findLivestreamByID))) {
      setLivestream(loadedLivestream.data?.findLivestreamByID);
    }
  }, [livestream, loadedLivestream, props.live?.room_id]);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{ height: '80%' }} // Ensures the grid occupies full height of the container
    >
      {props.live?.status === 'ON_GOING' ? (
        <>
          <Grid container sx={{ width: { xs: '75vw', sm: '85vw', md: '90vw' }, justifyContent: 'center', textAlign: 'center', p: { xs: '0 20px', sm: '0 20px', md: '0' } }}>
            <LivestreamVideoPlayer room_id={props.live?.room_id} status={props.live?.status} />
          </Grid>
          <Stack direction="column" sx={{ width: { xs: '25vw', sm: '15vw', md: '10vw' }, alignItems: 'center' }}>
            <IconButton component={loggedPlayer?.id ? Link : 'div'} onClick={loggedPlayer?.id ? undefined : handleOpenLogin} to={props.live?.author?.logo || props.live?.author?.company ? `/${(props.live?.author?.company || props.live?.author)?.name}` : props.live?.author?.avatar ? `/u/${props.live?.author?.username}` : props.live?.author?.__typename === 'FootballTeam' ? `/football_team/${props.live?.author?.short_id}` : ''} sx={{ mb: '20px' }}>
              <Avatar src={(props.live?.author?.logo || props.live?.author?.company?.logo || props.live?.author?.avatar)?.path} />
            </IconButton>

            {loggedPlayer?.id ? (
              <>
                <IconButton component={Link} to={`/ls/${props.live?.room_id}`}>
                  <Visibility />
                  <LiveIcon />
                </IconButton>

                {livestream?.liked_by?.find((p: any) => p?.id === inputFields.flexer_id) ? (
                  <IconButton onClick={() => handleDislikeLivestream(props.live?.room_id)} disabled={dislikedLivestream.loading} className={dislikedLivestream.loading ? 'button-disabled' : 'button-red-reversed'} sx={{ display: 'inline-block', textAlign: 'center', fontSize: '1em' }}>
                    {livestream?.liked_by?.length > 0 ? (
                      <>
                        <NumberFormatter number={livestream?.liked_by?.length} />
                        <br />
                      </>
                    ) : (
                      ''
                    )}
                    <Favorite />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => handleLikeLivestream(props.live?.room_id)} disabled={likedLivestream.loading} className={likedLivestream.loading ? 'button-disabled' : 'button-cancel'} sx={{ display: 'inline-block', textAlign: 'center', fontSize: '1em' }}>
                    {livestream?.liked_by?.length > 0 ? (
                      <>
                        <NumberFormatter number={livestream?.liked_by?.length} />
                        <br />
                      </>
                    ) : (
                      ''
                    )}
                    <Favorite />
                  </IconButton>
                )}

                <IconButton onClick={() => setOpenViewComments(true)} sx={{ 'display': 'inline-block', 'textAlign': 'center', 'fontSize': '1em', 'color': 'rgba(247, 251, 250, 1)', '&:hover': { bgcolor: 'transparent !important', color: 'rgba(102, 130, 19, 1)' } }}>
                  {livestream?.comments?.length > 0 ? (
                    <>
                      <NumberFormatter number={livestream?.comments?.length} />
                      <br />
                    </>
                  ) : (
                    ''
                  )}
                  <ChatBubble sx={{ color: 'inherit !important' }} />
                </IconButton>
                {livestream?.id ? <ViewCommentsModal open={openViewComments} handleClose={() => setOpenViewComments(false)} comments={livestream?.comments} status={livestream?.status} entity_id={livestream?.id} handleRefetch={handleRefetch} /> : ''}

                {livestream?.shared_by?.find((p: any) => p?.id === inputFields.flexer_id) ? (
                  <IconButton onClick={() => handleUnshareLivestream(props.live?.room_id)} disabled={unsharedLivestream.loading} className={unsharedLivestream.loading ? 'button-disabled' : 'button-green-reverse'} sx={{ 'display': 'inline-block', 'textAlign': 'center', 'fontSize': '1em', 'border': 'none !important', '&:hover': { bgcolor: 'transparent !important', color: 'rgba(102, 130, 19, 1)' } }}>
                    {livestream?.shared_by?.length > 0 ? (
                      <>
                        <NumberFormatter number={livestream?.shared_by?.length} />
                        <br />
                      </>
                    ) : (
                      ''
                    )}
                    <ShareIcon sx={{ color: 'inherit !important' }} />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => handleShareLivestream(props.live?.room_id)} disabled={sharedLivestream.loading} className={sharedLivestream.loading ? 'button-disabled' : ''} sx={{ 'display': 'inline-block', 'textAlign': 'center', 'fontSize': '1em', 'color': 'rgba(247, 251, 250, 1)', '&:hover': { bgcolor: 'transparent !important', color: 'rgba(102, 130, 19, 1)' } }}>
                    {livestream?.shared_by?.length > 0 ? (
                      <>
                        <NumberFormatter number={livestream?.shared_by?.length} />
                        <br />
                      </>
                    ) : (
                      ''
                    )}
                    <ShareIcon sx={{ color: 'inherit !important' }} />
                  </IconButton>
                )}
              </>
            ) : (
              ''
            )}
            <IconButton onClick={() => setIsMuted(!isMuted)} sx={{ mb: '20px' }}>
              {isMuted ? <Mic /> : <MicOff />}
            </IconButton>

            {props.previous_element ? (
              <IconButton onClick={props.previous_element} className="button-cancel" sx={{ mb: '10px' }}>
                <ArrowUpward />
              </IconButton>
            ) : (
              ''
            )}

            {props.next_element ? (
              <IconButton onClick={props.next_element} className="button-cancel" sx={{ mb: '10px' }}>
                <ArrowDownward />
              </IconButton>
            ) : (
              ''
            )}
          </Stack>
        </>
      ) : (
        <>
          <Grid container sx={{ width: { xs: '75vw', sm: '85vw', md: '90vw' }, justifyContent: 'center', p: { xs: '0 20px', sm: '0 20px', md: '0' } }}>
            <VideoPlayer url={props.live?.video_url} muted={isMuted} />
          </Grid>

          <Stack direction="column" sx={{ width: { xs: '25vw', sm: '15vw', md: '10vw' }, alignItems: 'center' }}>
            <IconButton component={loggedPlayer?.id ? Link : 'div'} onClick={loggedPlayer?.id ? undefined : handleOpenLogin} to={props.live?.author?.logo || props.live?.author?.company ? `/${(props.live?.author?.company || props.live?.author)?.name}` : props.live?.author?.avatar ? `/u/${props.live?.author?.username}` : props.live?.author?.__typename === 'FootballTeam' ? `/football_team/${props.live?.author?.short_id}` : ''} sx={{ mb: '20px' }}>
              <Avatar src={(props.live?.author?.logo || props.live?.author?.company?.logo || props.live?.author?.avatar)?.path} />
            </IconButton>

            <IconButton component={Link} to={`/ls/${props.live?.room_id}`}>
              <Visibility />
            </IconButton>

            {loggedPlayer?.id ? (
              <>
                {livestream?.liked_by?.find((p: any) => p?.id === inputFields.flexer_id) ? (
                  <IconButton onClick={() => handleDislikeLivestream(props.live?.room_id)} disabled={dislikedLivestream.loading} className={dislikedLivestream.loading ? 'button-disabled' : 'button-red-reversed'} sx={{ display: 'inline-block', textAlign: 'center', fontSize: '1em' }}>
                    {livestream?.liked_by?.length > 0 ? (
                      <>
                        <NumberFormatter number={livestream?.liked_by?.length} />
                        <br />
                      </>
                    ) : (
                      ''
                    )}
                    <Favorite />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => handleLikeLivestream(props.live?.room_id)} disabled={likedLivestream.loading} className={likedLivestream.loading ? 'button-disabled' : 'button-cancel'} sx={{ display: 'inline-block', textAlign: 'center', fontSize: '1em', color: 'rgba(247, 251, 250, 1)' }}>
                    {livestream?.liked_by?.length > 0 ? (
                      <>
                        <NumberFormatter number={livestream?.liked_by?.length} />
                        <br />
                      </>
                    ) : (
                      ''
                    )}
                    <Favorite />
                  </IconButton>
                )}

                <IconButton onClick={() => setOpenViewComments(true)} sx={{ 'display': 'inline-block', 'textAlign': 'center', 'fontSize': '1em', 'color': 'rgba(247, 251, 250, 1)', '&:hover': { bgcolor: 'transparent !important', color: 'rgba(102, 130, 19, 1)' } }}>
                  {livestream?.comments?.length > 0 ? (
                    <>
                      <NumberFormatter number={livestream?.comments?.length} />
                      <br />
                    </>
                  ) : (
                    ''
                  )}
                  <ChatBubble sx={{ color: 'inherit !important' }} />
                </IconButton>
                {livestream?.id ? <ViewCommentsModal open={openViewComments} handleClose={() => setOpenViewComments(false)} comments={livestream?.comments} status={livestream?.status} entity_id={livestream?.id} handleRefetch={handleRefetch} /> : ''}

                {livestream?.shared_by?.find((p: any) => p?.id === inputFields.flexer_id) ? (
                  <IconButton onClick={() => handleUnshareLivestream(props.live?.room_id)} disabled={unsharedLivestream.loading} className={unsharedLivestream.loading ? 'button-disabled' : ''} sx={{ 'display': 'inline-block', 'textAlign': 'center', 'fontSize': '1em', 'border': 'none !important', 'color': 'rgba(102, 130, 19, 1)', '&:hover': { bgcolor: 'transparent !important', color: 'rgba(102, 130, 19, 1)' } }}>
                    {livestream?.shared_by?.length > 0 ? (
                      <>
                        <NumberFormatter number={livestream?.shared_by?.length} />
                        <br />
                      </>
                    ) : (
                      ''
                    )}
                    <ShareIcon sx={{ color: 'inherit !important' }} />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => handleShareLivestream(props.live?.room_id)} disabled={sharedLivestream.loading} className={sharedLivestream.loading ? 'button-disabled' : ''} sx={{ display: 'inline-block', textAlign: 'center', fontSize: '1em', color: 'rgba(247, 251, 250, 1)' }}>
                    {livestream?.shared_by?.length > 0 ? (
                      <>
                        <NumberFormatter number={livestream?.shared_by?.length} />
                        <br />
                      </>
                    ) : (
                      ''
                    )}
                    <ShareIcon sx={{ color: 'inherit !important' }} />
                  </IconButton>
                )}

                <Controller
                  name="flexer_id"
                  control={control}
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      // label={`As`}
                      select
                      variant="outlined"
                      className="no-borders"
                      SelectProps={{
                        IconComponent: undefined,
                        sx: {
                          'color': 'inherit',
                          'border': 'none',
                          'bgcolor': 'transparent',
                          '& #mui-component-select-flexer_id': { p: '10px 0' },
                          '&::-ms-expand': {
                            display: 'none',
                          },
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          color: 'inherit',
                          p: '0 6px',
                          borderRadius: '5px',
                          textAlign: 'center',
                          width: '100%',
                        },
                      }}
                      sx={{ m: '0', p: '0', width: 'auto' }}
                    >
                      {ALL_FLEXERS.map((option: any) => (
                        <MenuItem key={option.id} value={option.id}>
                          <Grid
                            container
                            sx={{
                              justifyContent: 'start',
                              alignItems: 'center',
                            }}
                          >
                            <Avatar
                              src={option?.avatar}
                              sx={{
                                width: '25px',
                                height: '25px',
                              }}
                            />
                            &nbsp;&nbsp;
                            <Typography variant="caption">{option.name}</Typography>
                          </Grid>
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </>
            ) : (
              ''
            )}
            <IconButton onClick={() => setIsMuted(!isMuted)} sx={{ mb: '20px' }}>
              {isMuted ? <Mic /> : <MicOff />}
            </IconButton>

            {props.previous_element ? (
              <IconButton onClick={props.previous_element} className="button-cancel" sx={{ mb: '10px' }}>
                <ArrowUpward />
              </IconButton>
            ) : (
              ''
            )}

            {props.next_element ? (
              <IconButton onClick={props.next_element} className="button-cancel" sx={{ mb: '10px' }}>
                <ArrowDownward />
              </IconButton>
            ) : (
              ''
            )}
          </Stack>
        </>
      )}

      <LoginModal open={openLogin} handleClose={() => setOpenLogin(false)} />
    </Grid>
  );
};

const VideoPlayer: React.FC<IVideoPlayer> = (props: IVideoPlayer) => {
  const loggedPlayer = useAppSelector(selectPlayer);
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [position, setPosition] = React.useState(0);
  const [duration, setDuration] = React.useState(UNDEFINED_AS_ANY);

  const handlePlayVideo = () => {
    if (videoRef.current) {
      try {
        videoRef.current?.play().catch((error) => {
          console.error('Error playing video:', error);
          if (error.name === 'NotSupportedError') {
            // Handle the error appropriately, e.g., display a message to the user
            alert('This video format is not supported by your browser.');
          }
        });
        setIsPlaying(true);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handlePauseVideo = () => {
    if (videoRef.current) {
      try {
        videoRef.current?.pause();
        setIsPlaying(false);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const formatDuration = (value: number) => {
    const minute = Math.floor(value / 60);
    const secondLeft = value - minute * 60;
    return `${minute > 0 ? `${minute}:` : '00:'}${secondLeft < 10 ? `0${secondLeft.toFixed(0)}` : secondLeft.toFixed(0)}`;
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setPosition(newValue as number);
    if (videoRef.current) {
      videoRef.current.currentTime = newValue as number;
    }
  };

  React.useEffect(() => {
    if (!duration && videoRef.current?.duration) {
      setDuration(videoRef.current?.duration);
    }
  }, [duration, videoRef.current?.duration]);

  React.useEffect(() => {
    const video = videoRef.current;

    if (video) {
      const updateSlider = () => {
        if (isPlaying) {
          setPosition(video.currentTime);
        }
      };

      const interval = setInterval(updateSlider, 500); // Update every second

      return () => {
        clearInterval(interval); // Cleanup interval on component unmount or when isPlaying changes
      };
    }
  }, [isPlaying, duration]);

  return (
    <Grid sx={{ width: '90vw', maxWidth: '900px' }}>
      <CardMedia
        component={'video'}
        ref={videoRef}
        loop
        muted={props.muted}
        onClick={isPlaying ? handlePauseVideo : handlePlayVideo}
        style={{ borderRadius: '20px', maxHeight: '90vh' }} // Adjusted to maintain aspect ratio
      >
        <source src={props.url} type="video/mp4" />
        <source src={props.url} type="video/webm" />
        Your browser does not support the video tag.
      </CardMedia>
      <Slider
        aria-label="time-indicator"
        size="small"
        value={position}
        min={0}
        step={1}
        max={duration}
        onChange={handleSliderChange}
        sx={{ 'height': '4px', 'color': loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)', '& .MuiSlider-thumb': { 'color': loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)', '&:hover': { boxShadow: loggedPlayer?.app_configuration?.is_dark_mode ? '0px 0px 0px 8px rgba(247, 251, 250, 0.16)' : '0px 0px 0px 8px rgba(15,15,15, 0.16)' } } }}
      />
      {duration ? (
        <Grid
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: -2,
            color: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)',
          }}
        >
          <Typography variant="caption" sx={{ color: 'inherit' }}>
            {formatDuration(position)}
          </Typography>
          <Typography variant="caption" sx={{ color: 'inherit' }}>
            -{formatDuration(duration - position)}
          </Typography>
        </Grid>
      ) : (
        ''
      )}
    </Grid>
  );
};

export default React.memo(InfiniteVideoScroll);
